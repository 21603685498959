<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>マスター管理</el-breadcrumb-item>
      <el-breadcrumb-item>新規</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-setting"></i>&nbsp;マスター情報</span>
      </div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="種別区分" prop="kbnType">
          <el-select v-model="form.kbnType" style="width: 70%;">
            <el-option
              v-for="item in kbnTypeList"
              v-bind:key="item.value"
              v-bind:label="item.label"
              v-bind:value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="コード" prop="kbnCode">
          <el-input-number v-model="form.kbnCode" controls-position="right" :precision="0" :step="1" :min="1" :max="99999" style="width:20%;"></el-input-number>
        </el-form-item>
        <el-form-item label="表示名" prop="kbnName">
          <el-input v-model="form.kbnName" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="表示順" prop="kbnSeq">
          <el-input-number v-model="form.kbnSeq" controls-position="right" :precision="0" :step="1" :min="0" :max="99999" style="width:20%;"></el-input-number>
        </el-form-item>
        <el-form-item label="コメント" prop="kbnCmmt">
          <el-input v-model="form.kbnCmmt" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="原率" v-if="form.kbnType == 'SK008'">
          <el-input-number v-model="form.kbnPlm5" controls-position="right" :precision="2" :step="0.01" :min="0" :max="99.99" style="width:20%;"></el-input-number>
        </el-form-item>
        <el-form-item label="変換後名" prop="kbnPlm4" v-if="form.kbnType == 'SK011'">
          <el-input v-model="form.kbnPlm4" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="submitForm('form')">{{$t('addUser.create')}}</el-button>
          <el-button round @click="resetForm()">{{$t('addUser.back')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { addMaster,getParentList } from "@/api/master";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "AddMaster",
  data() {
    return {
      kbnTypeList: [
        {value:"SK001", label: "SK001 種別（申込書用）"},
        {value:"SK002", label: "SK002 サービス名（申込書用）"},
        {value:"SK003", label: "SK003 商品プラン名"},
        {value:"SK005", label: "SK005 掲載エリア"},
        {value:"SK006", label: "SK006 リスティング情報①"},
        {value:"SK007", label: "SK007 リスティング情報②"},
        {value:"SK008", label: "SK008 サービスマージン"},
        {value:"SK009", label: "SK009 掲載区分"},
        {value:"SK010", label: "SK010 キャンペーン名"},
        {value:"SK011", label: "SK011 商品プラン変換"},
        {value:"SK012", label: "SK012 サービス用職種"},
        {value:"SK013", label: "SK013 契約用職種"},
      ],
      parentList: [],
      form: {
        id: null,
        kbnType: null,
        kbnCode: 1,
        kbnName: "",
        kbnSeq: 0,
        kbnCmmt:"",
        kbnPlm4:"",
        kbnPlm5: null,
      },
      rules: {
        kbnType: [
          { required: true, message: "種別区分を入力してください。", trigger: "blur" }
        ],
        kbnCode: [
          { required: true, message: "コードを入力してください。", trigger: "blur" }
        ],
        kbnName: [
          { required: true, message: "表示名を入力してください。", trigger: "blur" }
        ],
        kbnSeq: [
          { required: true, message: "表示順を入力してください。", trigger: "blur" }
        ],
        kbnPlm4: [
          { required: true, message: "変換後名を入力してください。", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getParentList();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          addMaster({
            userId: this.accountInfo.id,
            ...this.form
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            //this.refreshData();
            this.$router.push(ROUTER_PATH.KBN_MASTER);
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$router.push(ROUTER_PATH.KBN_MASTER);
    },
    getParentList(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          getParentList({
              keyword: query
            }).then(response => {
              this.parentList = { ...response.data };
            });
        }, 200);
      } else {
        this.parentList = [];
      }
    }
  }
};
</script>
